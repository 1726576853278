import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import api from 'api';
import { telegramUserAuth } from 'api/auth';
import { IBalance } from 'types/balance';

interface IInitDataContext {
  telegramId: string;
  telegramDataString: string;
  telegramHash: string;
  balance: IBalance;
  setBalance: (balance: IBalance) => void;
}

const initBalance = {
  id: 0,
  balanceCoins: 0,
  balanceDiamonds: 0,
  balanceThanks: 0,
};

const initValue: IInitDataContext = {
  telegramId: '',
  telegramDataString: '',
  telegramHash: '',
  balance: initBalance,
  setBalance: () => {},
};

export const InitDataContext = createContext<IInitDataContext>(initValue);

const PassInitDataProvider: IInitDataContext = { ...initValue };

const InitDataContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAppLoading, setAppLoading] = useState(true);
  const [telegramId, setTelegramId] = useState('');
  const [telegramDataString, setTelegramDataString] = useState('');
  const [telegramHash, setTelegramHash] = useState('');
  const [balance, setBalance] = useState<IBalance>(initBalance);

  const [initDataUnsafe, initDataString] = useInitData();
  // console.log('initDataUnsafe: ', JSON.stringify(initDataUnsafe, null, 1));
  // console.log('initDataString: ', initDataString);
  const webApp = useWebApp();

  useEffect(() => {
    if (initDataString) {
      const dataStringURLSP = new URLSearchParams(initDataString);
      dataStringURLSP.delete('hash');
      setTelegramDataString(dataStringURLSP.toString());
    }

    if (initDataUnsafe) {
      const hash = initDataUnsafe.hash;
      setTelegramHash(hash);

      const telegramId = initDataUnsafe.user?.id.toString();
      setTelegramId(telegramId || '');
    }
  }, [initDataUnsafe, initDataString]);

  useEffect(() => {
    if (!isAppLoading || !telegramId) return;

    const getAuth = async () => {
      try {
        setAppLoading(true);
        const token = await telegramUserAuth();

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setAppLoading(false);
      } catch (e) {
        webApp.close();
      }
    };

    getAuth();
  }, [telegramId]);

  PassInitDataProvider.telegramId = telegramId;
  PassInitDataProvider.telegramDataString = telegramDataString;
  PassInitDataProvider.telegramHash = telegramHash;
  PassInitDataProvider.balance = balance;
  PassInitDataProvider.setBalance = setBalance;

  if (isAppLoading) return null;

  return (
    <InitDataContext.Provider
      value={{
        telegramId,
        telegramDataString,
        telegramHash,
        balance,
        setBalance,
      }}
    >
      {children}
    </InitDataContext.Provider>
  );
};

export { InitDataContextProvider, PassInitDataProvider };
