import { FC } from 'react';

import { TOrderStatus } from 'components/ShopPage';
import { ICurrency } from 'types/currency';

import InitialOrderPopup from './InitialOrderPopup';
import InsufficientBalancePopup from './InsufficientBalancePopup';
import SuccessfulOrderPopup from './SuccessfulOrderPopup';

import styles from './OrderPopup.module.scss';

export interface IOrderPopupProps {
  type: TOrderStatus;
  onClickAccept?: (comment?: string) => void;
  onClickDecline?: () => void;
  onClosePopup?: () => void;
  currency?: ICurrency;
  price?: string | number;
}

const OrderPopup: FC<IOrderPopupProps> = ({
  currency,
  price,
  onClickAccept,
  onClickDecline,
  onClosePopup,
  type,
}) => {
  const renderComponent = () => {
    switch (type) {
      case 'confirm':
        return (
          <InitialOrderPopup
            currency={currency}
            price={price}
            onClickAccept={onClickAccept}
            onClickDecline={onClickDecline}
          />
        );
      case 'success':
        return <SuccessfulOrderPopup onClosePopup={onClosePopup} />;
      case 'insufficient':
        return (
          <InsufficientBalancePopup
            onClosePopup={onClosePopup}
            currency={currency}
          />
        );
      default:
        return null;
    }
  };

  return <div className={styles.order_popup}>{renderComponent()}</div>;
};

export default OrderPopup;
