import { useContext, useEffect, useState } from 'react';

import { postOrder } from 'api/orders';
import { getProducts } from 'api/products';
import { getUserBalance } from 'api/users';
import { AxiosError } from 'axios';
import BackButton from 'components/BackButton';
import CardPopup from 'components/CardPopup';
import Categories from 'components/Categories';
import Modal from 'components/Modal';
import OrderPopup from 'components/OrderPopup';
import ShopBalance from 'components/ShopBalance';
import { InitDataContext } from 'context/initDataContext';
import { productsDataFormat } from 'helpers/productsDataFormat';
import { ICategory } from 'types/categories';
import { ICategorizedProducts, IProduct } from 'types/products';
import { ID } from 'types/users';

import ShopPageContent from './ShopPageContent';

import styles from './ShopPage.module.scss';

export type TOrderStatus = 'confirm' | 'success' | 'insufficient' | null;

const ShopPage = () => {
  const { setBalance } = useContext(InitDataContext);

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isProductInfoPopupOpen, setProductInfoPopupOpen] = useState(false);
  const [isOrderPopupOpen, setOrderPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<null | IProduct>(null);
  const [orderStatus, setOrderStatus] = useState<TOrderStatus>(null);

  const [categorizedProducts, setCategorizedProducts] = useState<
    ICategorizedProducts[]
  >([]);

  useEffect(() => {
    getProducts()
      .then((data) => {
        const { categoriesData, categorizedProductsData } = productsDataFormat(
          data.result
        );

        setCategories(categoriesData);
        setCategorizedProducts(categorizedProductsData);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (orderStatus === 'success') {
      const fetchData = async () => {
        try {
          const { data } = await getUserBalance();
          setBalance(data);
        } catch (e) {
          console.log(e);
        }
      };

      fetchData();
    }
  }, [orderStatus]);

  const handleSelectProduct = (productId: number, categoryId: ID) => {
    setSelectedProduct(
      categorizedProducts
        .find((category) => categoryId === category.id)
        ?.products?.find((product) => productId === product.id) || null
    );

    setProductInfoPopupOpen(true);
  };

  const handleClosePopup = () => {
    setTimeout(() => {
      setSelectedProduct(null);
      setOrderStatus(null);
    }, 300);

    setOrderPopupOpen(false);
    setProductInfoPopupOpen(false);
  };

  const handleOpenTransactionConfirm = () => {
    setProductInfoPopupOpen(false);
    setOrderPopupOpen(true);
    setOrderStatus('confirm');
  };

  const handleDeclineTransaction = () => {
    setTimeout(() => {
      setProductInfoPopupOpen(true);
    }, 300);

    setOrderPopupOpen(false);
  };

  const handleAcceptTransaction = (comment?: string) => {
    const fetchData = async () => {
      try {
        await postOrder(Number(selectedProduct?.id), comment);

        setOrderStatus('success');
      } catch (e) {
        console.log(e);

        if (e instanceof AxiosError) {
          if (e.response?.status === 422) {
            setOrderStatus('insufficient');
          }
        }
      }
    };

    fetchData();
  };

  return (
    <div className={styles.shop_page}>
      <div className={styles.top}>
        <div className={styles.balance}>
          <BackButton />
          <ShopBalance />
        </div>
        <Categories categories={categories} />
      </div>
      <ShopPageContent
        onSelectProduct={handleSelectProduct}
        products={categorizedProducts}
      />
      <Modal
        isOpen={isProductInfoPopupOpen}
        onClose={handleClosePopup}
        modalClassName={styles.card_popup__modal}
        withCloseIcon
      >
        <CardPopup
          onClick={handleOpenTransactionConfirm}
          {...selectedProduct}
        />
      </Modal>
      <Modal
        isOpen={isOrderPopupOpen}
        onClose={handleClosePopup}
        modalClassName={styles.order_popup__modal}
      >
        <OrderPopup
          type={orderStatus}
          currency={selectedProduct?.currency}
          price={selectedProduct?.price}
          onClosePopup={handleClosePopup}
          onClickAccept={handleAcceptTransaction}
          onClickDecline={handleDeclineTransaction}
        />
      </Modal>
    </div>
  );
};

export default ShopPage;
