import { CURRENCY_EMOJI } from 'constants/currency';

import { ChangeEvent, FC, useState } from 'react';

import cn from 'clsx';
import { IOrderPopupProps } from 'components/OrderPopup';
import OrdersButton from 'components/OrdersButton';
import Input from 'components/ui/Input';
import Text from 'components/ui/Text';

import styles from '../OrderPopup.module.scss';

const InitialOrderPopup: FC<
  Omit<IOrderPopupProps, 'type' | 'onClosePopup'>
> = ({ currency, price, onClickAccept, onClickDecline }) => {
  const [comment, setComment] = useState('');

  const accept = () => {
    onClickAccept?.(comment);
  };

  return (
    <>
      <div className={cn(styles.title, styles.title__initial)}>
        <Text variant="h3">Ты уверен?</Text>
      </div>
      <div className={styles.initial_text_block}>
        <Text
          variant="body-1"
          className={cn(styles.description, styles.description__initial)}
        >
          Ты потратишь
        </Text>
        <div className={styles.initial_text_block__right}>
          <Text variant="h4" className={styles.initial_text_block_price}>
            {price}
          </Text>
          <div>{currency && CURRENCY_EMOJI[currency.name]}</div>
        </div>
      </div>
      <Input
        placeholder="Комментарий к заказу"
        onInput={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setComment(e.target.value)
        }
      />
      <div className={styles.actions}>
        <OrdersButton
          className={cn(styles.actions__button)}
          type="button"
          onClick={accept}
          variant="primary"
        >
          <Text as="span" variant="subtitle-3">
            Да
          </Text>
        </OrdersButton>
        <OrdersButton
          className={styles.actions__button}
          type="button"
          onClick={onClickDecline}
          variant="secondary"
        >
          <Text as="span" variant="subtitle-3">
            Вернуться назад
          </Text>
        </OrdersButton>
      </div>
    </>
  );
};

export default InitialOrderPopup;
