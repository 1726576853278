export enum EBalanceOperation {
  monthly_burning = 'monthly_burning',
  planning_replenishment = 'planning_replenishment',
  replenishment = 'replenishment',
  withdrawal = 'withdrawal',
  acknowledgement = 'acknowledgement',
  thanks = 'thanks',
  cashback = 'cashback',
}

export interface IBalance {
  id: number;
  balanceCoins: number;
  balanceDiamonds: number;
  balanceThanks: number;
}
