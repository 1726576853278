import { formatDate } from 'helpers/dateFormat';
import { EBalanceOperation } from 'types/balance';
import { ETransactionDetails } from 'types/transactions';
import { ITransaction, ITransactionDataFormatted } from 'types/users';

export const formatTransactionData = (
  transactions: ITransaction[]
): ITransactionDataFormatted[] => {
  const getOperationType = ({
    tipsTransactions,
    user,
    amount,
    details,
  }: ITransaction) => {
    const senderId = tipsTransactions?.tips?.sender?.id;

    if (
      details === ETransactionDetails.MONTHLY_BURNING ||
      details === ETransactionDetails.USED_ALL_TIPS
    ) {
      return EBalanceOperation.monthly_burning;
    }

    if (details === ETransactionDetails.CASHBACK) {
      return EBalanceOperation.cashback;
    }

    if (details === ETransactionDetails.PLANNING_REPLENISHMENT) {
      return EBalanceOperation.planning_replenishment;
    }

    if (tipsTransactions && senderId) {
      if (senderId !== user.id) {
        return EBalanceOperation.thanks;
      }

      return EBalanceOperation.acknowledgement;
    }

    if (amount > 0) {
      return EBalanceOperation.replenishment;
    }

    return EBalanceOperation.withdrawal;
  };

  const getDetails = (item: ITransaction) => {
    let formattedDetails = item.details;

    switch (item.details) {
      case ETransactionDetails.TRANSACTION_DETAILS_ORDER_NEW:
        formattedDetails = `Заказ №${item.orderTransaction?.orderId}`;
        break;
      case ETransactionDetails.TRANSACTION_DETAILS_ORDER_REFUND:
        formattedDetails = `Возврат за заказ №${item.orderTransaction?.orderId}`;
        break;
      case ETransactionDetails.USED_ALL_TIPS:
        formattedDetails = '+20 в карму';
        break;
      case ETransactionDetails.MONTHLY_BURNING:
        formattedDetails = 'А надо было тратить)';
        break;
      case ETransactionDetails.PLANNING_REPLENISHMENT:
        formattedDetails = 'Не забудь поблагодарить коллег)';
        break;
      case ETransactionDetails.EVERY_YEAR_PAYMENTS:
        formattedDetails = 'Спасибо, что ты часть команды 🧡';
        break;
      case ETransactionDetails.CASHBACK:
        formattedDetails = 'Кэшбек за спасибо';
        break;
      default:
    }

    if (item.tipsTransactions) {
      const { sender, receiver } = item.tipsTransactions.tips;

      if (sender.id === item.user.id) {
        return `для ${receiver.fullName}`;
      }

      return `от ${sender.fullName}`;
    }

    return formattedDetails;
  };

  return transactions.map((item) => ({
    ...item,
    id: item.id,
    operationType: getOperationType(item),
    currency: item.currency.name,
    description: getDetails(item),
    date: formatDate(item.createdAt),
  }));
};
