import { ICategory } from 'types/categories';
import { ICategorizedProducts, IProduct } from 'types/products';
import { ID } from 'types/users';

const sortByOrder = (
  a: ICategory | ICategorizedProducts,
  b: ICategory | ICategorizedProducts
) => {
  if (a.order < b.order) return -1;

  if (a.order > b.order) return 1;

  return 0;
};

export const productsDataFormat = (products: IProduct[]) => {
  const categorizedProducts = products.reduce(
    (categorizedProducts, product) => {
      if (!categorizedProducts[product.category.id]) {
        categorizedProducts = {
          ...categorizedProducts,
          [product.category.id]: {
            ...product.category,
            products: [product],
          },
        };
      } else {
        categorizedProducts[product.category.id].products?.push(product);
      }

      return categorizedProducts;
    },
    {} as Record<ID, ICategorizedProducts>
  );

  // ToDo: remove this. Probably we can replace ICategory with ICategorizedProducts interface in most places
  const categoriesData = Object.values(categorizedProducts)
    .map(({ products: _, ...category }) => category)
    .sort(sortByOrder);

  const categorizedProductsData = Object.values(categorizedProducts)
    .map((category) => ({
      ...category,
      products: category.products?.sort(sortByOrder),
    }))
    .sort(sortByOrder);

  return { categoriesData, categorizedProductsData };
};
