import { FC, InputHTMLAttributes, ChangeEvent, useRef } from 'react';

import cn from 'clsx';

import styles from './Input.module.scss';

interface IBaseInputProps {
  className?: string;
}

type IInputProps = IBaseInputProps &
  Omit<InputHTMLAttributes<HTMLTextAreaElement>, keyof IBaseInputProps>;

const Input: FC<IInputProps> = ({ className, onInput, ...rest }) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${e.target.scrollHeight}px`;
    }
    onInput?.(e);
  };

  return (
    <div className={cn(styles.input, className)}>
      <textarea
        ref={ref}
        className={styles.input__area}
        rows={1}
        onInput={handleInput}
        {...rest}
      />
    </div>
  );
};

export default Input;
