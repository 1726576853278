import { CURRENCY_EMOJI } from 'constants/currency';

import { FC } from 'react';

import { ReactComponent as CommentIcon } from 'components/icons/svg/comment.svg';
import { ReactComponent as EllipseIcon } from 'components/icons/svg/elipseIcon.svg';
import OrderStatus from 'components/OrderList/OrderItem/OrderStatus';
import Text from 'components/ui/Text';
import { formatDate } from 'helpers/dateFormat';
import { Tooltip } from 'react-tooltip';
import { IProduct } from 'types/products';
import { TProgressStages } from 'types/progressStages';
import { ID } from 'types/users';

import styles from './Order.module.scss';

export interface IOrderListItem {
  id: ID;
  date: string;
  status: TProgressStages;
  product: IProduct;
  refundDescription: string;
  comment?: string;
}

const OrderItem: FC<IOrderListItem> = ({
  id,
  product,
  date,
  status,
  refundDescription,
  comment,
}) => (
  <div className={styles.order_item}>
    <div className={styles.order_item__left}>
      <div className={styles.image}>
        <img
          src={product.imageUrl[0] || '/images/placeholder_image.png'}
          alt={product.name}
        />
      </div>
    </div>
    <div className={styles.order_item__right}>
      <div className={styles.text}>
        <div className={styles.text__heading}>
          <Text className={styles.text__title} variant="subtitle-4">
            {product.name}
          </Text>
          {comment && (
            <>
              <div
                className={styles.text__comment__image}
                data-tooltip-id={`order-comment-hint-${id}`}
                data-tooltip-place="top-end"
              >
                <CommentIcon />
              </div>
              <Tooltip
                className={styles.text__comment__tooltip}
                id={`order-comment-hint-${id}`}
              >
                <Text variant="title-1">{comment}</Text>
              </Tooltip>
            </>
          )}
        </div>
        <div className={styles.text__block}>
          <Text variant="title-1">{product.price}</Text>
          <Text variant="title-1" className={styles.text__emoji}>
            {CURRENCY_EMOJI[product.currency.name]}
          </Text>
          <div className={styles.text__ellipse}>
            <EllipseIcon />
          </div>
          <Text variant="title-1">{formatDate(date)}</Text>
        </div>
      </div>
      <div className={styles.order_item__right__info}>
        <OrderStatus id={id} status={status} hint={refundDescription} />
        <Text
          as="span"
          variant="title-1"
          className={styles.order_item__right__info__id}
        >
          заказ №{id}
        </Text>
      </div>
    </div>
  </div>
);

export default OrderItem;
